import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { useMapContext } from "../context/MapContext";
import { addStationMarker } from "../utils/addMarker";
import { TourData, StationOverview } from "../types";
import { useNavigate } from "react-router-dom";

const Map: React.FC = () => {
  const { setMapLoaded, setDataLoaded } = useMapContext();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const navigate = useNavigate();

  const handleMarkerClick = (station: StationOverview) => {
    console.log("Marker clicked:", station);
    navigate(`/station/${station.station_id}?station_id=${station.station_id}`);
  };

  const updateMaxZoom = () => {
    const screenWidth = window.innerWidth;
    let newMaxZoom;
    if (screenWidth < 600) {
      newMaxZoom = 2.8;
    } else if (screenWidth < 1200) {
      newMaxZoom = 2.3;
    } else {
      newMaxZoom = 2.5;
    }
    if (mapRef.current) {
      mapRef.current.setMaxZoom(newMaxZoom);
    }
  };

  useEffect(() => {
    if (!mapContainerRef.current) return;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE as string,
      zoom: window.innerWidth < 600 ? 1 : 1.8,
      maxZoom: 2.5,
      center: [10, 50],
      // center: [-85.84254881078989, 6.508332036536046],
      projection: { name: "globe" },
      dragRotate: false,
    });

    mapRef.current = map;

    // map.on("move", () => {
    //   console.log("Map moved:", map.getCenter());
    // });
    map.on("load", () => {
      setMapLoaded(true);
      updateMaxZoom(); // Set initial maxZoom based on current screen width

      fetch(process.env.REACT_APP_RDX_TOUR_API_ENDPOINT as string)
        .then((response) => response.json())
        .then((data: TourData) => {
          const { stations } = data;
          if (Array.isArray(stations)) {
            stations.forEach((station, index) => {
              addStationMarker({ map, station, onClick: handleMarkerClick });
            });
            setDataLoaded(true);

            // Animación de entrada
            setTimeout(() => {
              map.flyTo({
                // lat: 0.894883519975977
                // lng: -49.31432313141548
                // center: [-71.542969, -35.675147], // Coordenadas de Chile
                center: [-49.31432313141548, 0.894883519975977], // Coordenadas de Chile
                // center: [0.894883519975977, -49.31432313141548], // Coordenadas de Chile
                speed: 0.1,
                essential: true,
              });
            }, 2000);
          } else {
            console.error("Stations data is not an array:", stations);
          }
        })
        .catch((error) => {
          console.error("Error loading API data:", error);
        });
    });

    map.on("error", (e) => {
      console.error("Map load error:", e.error);
    });

    map.on("zoom", () => {
      const maxZoom = map.getMaxZoom();
      if (map.getZoom() > maxZoom * 0.7) {
        document.body.classList.remove("hide-labels");
      } else {
        document.body.classList.add("hide-labels");
      }
    });

    window.addEventListener("resize", updateMaxZoom);

    return () => {
      window.removeEventListener("resize", updateMaxZoom);
      map.remove();
    };
  }, []);

  return (
    <div ref={mapContainerRef} style={{ height: "100vh", width: "100%" }} />
  );
};

export default Map;
